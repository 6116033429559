var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('custom-skeleton-loader',{attrs:{"LoadingFlag":_vm.SkeletonLoadingFlag}}),(!_vm.SkeletonLoadingFlag)?_c('v-card-text',{staticClass:"text-h6 black--text ml-1"},[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_vm._v(" President Date   "),_c('span',{staticClass:"ml-2"},[_vm._v(":  "+_vm._s(_vm.LdmtDate.LdmtDate)+" ")]),_c('v-btn',{staticClass:"white--text pt-2 m-2",attrs:{"width":"100px","small":"","color":"#f36364","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.clickPresidentDate()}}},[_c('h6',{staticClass:"text-body-2"},[_vm._v("Selected Date")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_vm._v(" President Approval Status "),_c('span',[_vm._v(" :  "),_c('v-chip',{attrs:{"small":"","color":_vm.DatesShow.PresidentApprovalStatusColor
                ? _vm.DatesShow.PresidentApprovalStatusColor
                : '#d5cfac'}},[_vm._v(" "+_vm._s(_vm.DatesShow.PresidentApprovalStatus ? _vm.DatesShow.PresidentApprovalStatus : "Yet to wait")+" ")])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_vm._v(" LDMT Status       "),_c('span',{staticClass:"ml-12"},[_vm._v(":   "),_c('v-chip',{attrs:{"small":"","color":_vm.DatesShow.CompletedStatusColor
                ? _vm.DatesShow.CompletedStatusColor
                : '#d5cfac'}},[_vm._v(" "+_vm._s(_vm.DatesShow.CompletedStatusTxt ? _vm.DatesShow.CompletedStatusTxt : "Yet to wait")+" ")])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_vm._v(" NHQ Approval Status   "),_c('span',{staticClass:"ml-0"},[_vm._v(":   "),_c('v-chip',{attrs:{"small":"","color":_vm.DatesShow.TrainingStatusColor
                ? _vm.DatesShow.TrainingStatusColor
                : '#d5cfac'}},[_vm._v(" "+_vm._s(_vm.DatesShow.TrainingStatusTxt ? _vm.DatesShow.TrainingStatusTxt : "Yet to wait")+" ")])],1)]),_c('v-col',[(_vm.DatesShow.ApprovedFlag)?_c('v-btn',{staticClass:"white--text pt-2 mr-2",attrs:{"color":"#5fc52e","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.approvedStatus()}}},[_c('h6',[_vm._v("President Approved")])]):_vm._e(),(_vm.DatesShow.DownloadFlag)?_c('v-btn',{staticClass:"white--text pt-2",attrs:{"href":_vm.DatesShow.FilePath,"target":"_blank","rel":"noopener noreferrer","color":"red","rounded":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download Report ")],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"75%"},model:{value:(_vm.presidentDatePrompt),callback:function ($$v) {_vm.presidentDatePrompt=$$v},expression:"presidentDatePrompt"}},[(_vm.presidentDatePrompt)?_c('lom-training-date',{attrs:{"pageTitle":"President Date","pageDescription":"President Date","presidentDatePrompt":_vm.presidentDatePrompt,"recordData":_vm.rowData},on:{"hidepresidentDatePrompt":function($event){return _vm.hidepresidentDatePrompt($event)}}}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"75%"},model:{value:(_vm.lomInformationStatus),callback:function ($$v) {_vm.lomInformationStatus=$$v},expression:"lomInformationStatus"}},[(_vm.lomInformationStatus)?_c('lom-training-approval',{attrs:{"pageTitle":"President Date","pageDescription":"President Date","lomInformationStatusPrompt":_vm.lomInformationStatusPrompt,"recordData":_vm.selectedData},on:{"hidelomInformationStatusPrompt":function($event){return _vm.hidelomInformationStatusPrompt($event)}}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }