<template>
  <div class="text-h6 black--text">
    <custom-skeleton-loader :LoadingFlag="SkeletonLoadingFlag" />
    <v-row wrap class="ml-1" v-if="!SkeletonLoadingFlag">
      <v-col cols="12" md="12" lg="12" sm="12" align="left">
        LO Status :
        <v-chip
          :color="getActiveStatusColor(AffiliationData.LomStatusTxt)"
          draggable
          dark
          class="text-h5 px-6 py-4 ml-4"
          >{{ AffiliationData.LomStatusTxt }}</v-chip
        >&nbsp;
        <v-icon icon size="25px" color="primary" @click.prevent="pageData">
          mdi-sync
        </v-icon>
      </v-col>

      <v-col cols="12" md="12" lg="12" sm="12" align="left">
        Affilication Document :
        <span v-if="AffiliationData.AffiliationDocumentPath != ''">
          <a :href="AffiliationData.AffiliationDocumentPath" target="_blank"
            >Download</a
          >
        </span>
        <span
          v-if="
            AffiliationData.AffiliationDocumentPath == '' &&
            AffiliationData.AffiliationDocumentRequestStatus != 1
          "
        >
          <v-btn
            color="black"
            draggable
            dark
            rounded
            class="text-h5 py-4 ml-4"
            :loading="LoadingFlag"
            @click.prevent="confirmAlert()"
          >
            <span class="text-h6 white--text">Request </span>
          </v-btn>
        </span>
        <span
          v-if="AffiliationData.AffiliationDocumentRequestStatus == 1"
          class="text-h6"
          style="color: #2e7d32"
        >
          Request Sent !
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  props: {
    affiliationPrompt: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      AffiliationData: "",
      LoadingFlag: true,

      SkeletonLoadingFlag: true,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      CurrentYearId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAffiliationDocuments();
    },
    getAffiliationDocuments() {
      console.log("getAffiliationDocuments is called");

      this.SkeletonLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/affiliation-document-list";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SkeletonLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.AffiliationData = response.data.records;
            console.log("records=" + JSON.stringify(thisIns.AffiliationData));

            // thisIns.toast("success", output);
          } else {
            // thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SkeletonLoadingFlag = false;
        });
    },
    confirmAlert() {
      console.log("confirmAlert called");

      var htmlTxt = "";
      htmlTxt += "Do you want to Request for Permanent Affiliation Document?";
      Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        html: htmlTxt,
        showCancelButton: true,
        confirmButtonText: `Yes`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `No`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestAffiliationDocuments();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    requestAffiliationDocuments() {
      console.log("requestAffiliationDocuments is called");

      this.LoadingFlag = true;

      console.log("LoadingFlag" + this.LoadingFlag);

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/request-affiliation-document";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          console.log("LoadingFlag" + thisIns.LoadingFlag);

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.getAffiliationDocuments();
          } else {
            thisIns.sweetAlert("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
        });
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style>
</style>
