<template>
  <div id="card">
    <v-card>
      <v-card-title
        ><h2>{{ LomCode }} - {{ LomName }} LO Information</h2></v-card-title
      >
      <br />
      <v-card-text>
        <v-row wrap>
          <v-col cols="12" lg="4" md="4" sm="6">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12" lg="10" md="6" sm="6">
                    <h4 class="ml-4">Specimen Signature Card</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <ss-card></ss-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">LO Affilication Status</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <affiliation-document-list></affiliation-document-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">LO Votes</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <voting-count></voting-count>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" class="mb-4">
            <br />
            <v-card class="mt-4 mx-auto" max-width="400">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">Location Details</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <location-information-list></location-information-list>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" lg="8" md="8" sm="6" class="mb-4">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">Bank Details</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text>
                <bank-information-list></bank-information-list>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="12" lg="4" md="4" sm="6">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(200% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">LDMT Details</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <ldmt-status></ldmt-status>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" v-if="LavCardFlag">
            <br />
            <v-card class="mt-4 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="3"
                max-width="calc(100% - 62px)"
              >
                <v-row>
                  <v-col cols="12">
                    <h4 class="ml-4">LAV Details</h4>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-card-text class="pt-0">
                <lav-status></lav-status>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import LocationInformationList from "@/view/pages/erp/lom/lom-information/LocationInformationList.vue";
import BankInformationList from "@/view/pages/erp/lom/lom-information/BankInformationList.vue";
import AffiliationDocumentList from "@/view/pages/erp/lom/affilication-document/AffiliationDocumentList.vue";
import SsCard from "@/view/pages/erp/lom/lom-information/SsCard.vue";
import VotingCount from "@/view/pages/erp/lom/lom-information/VotingCount.vue";
import LdmtStatus from "@/view/pages/erp/lom/lom-information/LdmtStatus.vue";
import LavStatus from "@/view/pages/erp/lom/lom-information/LavStatus.vue";

export default {
  mixins: [common],
  components: {
    BankInformationList,
    LocationInformationList,
    AffiliationDocumentList,
    SsCard,
    VotingCount,
    LdmtStatus,
    LavStatus,
  },
  data() {
    return {
      VotingRightsStatus: "",
      DocumentPath: "",
      uploadDocumentPrompt: false,
      EditRecordPrompt: false,
      presidentDatePrompt: false,
      lomInformationStatus: false,

      EligibleCount: 0,
      selectedData: {},
      VotingData: {},

      LdmtCardFlag: true,
      LavCardFlag: false,

      LomName: "",
      LomCode: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var LomName = this.$session.get("LomName");
        LomName = LomName == (null || undefined) ? "" : LomName;
        console.log({ LomName });
        this.LomName = LomName;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath = server_url + "assets/sample/voting_rights.pdf";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "voting_rights",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    clickPresidentDate() {
      console.log("clickDate called");
      var tr = this.Records;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.presidentDatePrompt = true;
        this.rowData = tr;
      } else {
        var message = "Selected Date failed";
        this.toast("error", message);
      }
    },
    approvedStatus() {
      console.log("clickDate called");
      var tr = this.Records;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.lomInformationStatus = true;
        this.selectedData = tr;
      } else {
        var message = "Votingdescription failed";
        this.toast("error", message);
      }
    },
    hideUploadRecordPrompt() {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.EditRecordPrompt = false;
    },
    editData() {
      console.log("editData called");
      var tr = this.VotingData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Votingdescription failed";
        this.toast("error", message);
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>