<template>
  <div>
    <custom-skeleton-loader :LoadingFlag="SkeletonLoadingFlag" />
    <v-card-text class="text-h6 black--text ml-1" v-if="!SkeletonLoadingFlag">
      <v-row wrap>
        <v-col cols="12" lg="12" md="12" sm="12">
          President Date &nbsp;
          <span class="ml-2"
            >: &nbsp;
            <v-chip small color="#d5cfac" v-if="rowData.LdmtDateFlag">
              {{ rowData.LdmtDateTxt }}
            </v-chip>
          </span>
          <v-btn
            v-if="!rowData.LdmtDateFlag"
            width="100px"
            small
            color="#f36364"
            class="white--text pt-2 m-2"
            rounded
            @click.prevent="clickPresidentDate()"
            ><h6 class="text-body-2">Select Date</h6></v-btn
          >
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          President Approval Status
          <span>
            :&nbsp;
            <v-chip small :color="rowData.VerificationStatusColor">
              {{ rowData.VerificationStatusTxt }}
            </v-chip>
          </span>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          ZVP Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp;
          <span>
            :&nbsp;
            {{ rowData.ZvpLdmtDate }}
          </span>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          LDMT Status &nbsp; &nbsp; &nbsp;
          <span class="ml-12"
            >: &nbsp;
            <v-chip
              small
              :color="
                rowData.CompletedStatusColor
                  ? rowData.CompletedStatusColor
                  : '#d5cfac'
              "
            >
              {{
                rowData.CompletedStatusTxt
                  ? rowData.CompletedStatusTxt
                  : "Yet to wait"
              }}
            </v-chip>
          </span>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          NHQ Approval Status &nbsp;
          <span class="ml-0"
            >: &nbsp;
            <v-chip
              small
              :color="
                rowData.TrainingStatusColor
                  ? rowData.TrainingStatusColor
                  : '#d5cfac'
              "
            >
              {{
                rowData.TrainingStatusTxt
                  ? rowData.TrainingStatusTxt
                  : "Yet to wait"
              }}
            </v-chip>
          </span>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12" class="text-center">
          <v-btn
            v-if="rowData.ApprovalFlag"
            @click.prevent="approvedStatus()"
            color="primary"
            small
            class="text-white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>mdi-shield-check</v-icon>Presidet Approval
          </v-btn>
          &nbsp;
          <v-btn
            v-if="rowData.DownloadFlag"
            :href="rowData.FilePath"
            target="_blank"
            color="red"
            small
            class="text-white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>mdi-file-pdf-box</v-icon>Download
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="presidentDatePrompt" persistent max-width="75%">
        <lom-training-date
          pageTitle="LDMT Preferred Date"
          :presidentDatePrompt="presidentDatePrompt"
          @hideDialog="hidePresidentDatePrompt($event)"
          :recordData="rowData"
          v-if="presidentDatePrompt"
        ></lom-training-date>
      </v-dialog>
      <v-dialog v-model="lomInformationStatusPrompt" persistent max-width="75%">
        <lom-training-approval
          pageTitle="LDMT Approve by President"
          :lomInformationStatusPrompt="lomInformationStatusPrompt"
          @hideDialog="hideLomInformationStatusPrompt($event)"
          :recordData="rowData"
          v-if="lomInformationStatusPrompt"
        ></lom-training-approval>
      </v-dialog>
    </v-card-text>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import VotingCount from "@/view/pages/erp/lom/lom-information/VotingCount.vue";
import LomTrainingDate from "@/view/pages/erp/lom/lom-information/LomTrainingDate.vue";
import LomTrainingApproval from "@/view/pages/erp/lom/lom-information/LomTrainingApproval.vue";
import LdmtStatus from "@/view/pages/erp/lom/lom-information/LdmtStatus.vue";

export default {
  mixins: [common],
  components: {
    LomTrainingDate,
    LomTrainingApproval,
    LdmtStatus,
  },
  data() {
    return {
      VotingRightsStatus: "",
      DocumentPath: "",
      uploadDocumentPrompt: false,
      EditRecordPrompt: false,
      presidentDatePrompt: false,
      lomInformationStatusPrompt: false,

      SkeletonLoadingFlag: true,

      rowData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath = server_url + "assets/sample/voting_rights.pdf";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "voting_rights",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    clickPresidentDate() {
      console.log("clickDate called");
      var tr = this.rowData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.presidentDatePrompt = true;
      } else {
        var message = "Selected Date failed";
        this.toast("error", message);
      }
    },
    approvedStatus() {
      console.log("clickDate called");
      var tr = this.rowData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.lomInformationStatusPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Votingdescription failed";
        this.toast("error", message);
      }
    },
    hidePresidentDatePrompt(flag) {
      console.log("hidePresidentDatePrompt called");
      this.presidentDatePrompt = false;
      console.log({ flag });
      if (flag) {
        this.pageData();
      }
    },
    hideLomInformationStatusPrompt(flag) {
      console.log("hideLomInformationStatusPrompt called");
      this.lomInformationStatusPrompt = false;
      console.log({ flag });
      if (flag) {
        this.pageData();
      }
    },
    hideUploadRecordPrompt() {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      this.pageData();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.rowData = {};
        this.SkeletonLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training/status-info";
        var upload = {
          UserInterface: 1,
          // LomCode: LomCode,
          // Category: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SkeletonLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.rowData = records;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SkeletonLoadingFlag = false;
          });
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
