<template>
  <div id="card">
    <custom-skeleton-loader :LoadingFlag="SkeletonLoadingFlag" />
    <v-card-text v-if="!SkeletonLoadingFlag">
      <v-row wrap class="mx-2">
        <v-col cols="12" md="12" class="text-h6 bold black--text dark">
          <span class="dark--text mt-5">
            <h1 style="margin-left:100px; font-size:50px margin-bottom:30px">
              <b style="font-size: 50px">{{ VotingData.VotingCount }}</b>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="editData"
                    >mdi-information</v-icon
                  >
                </template>
                <span>info</span>
              </v-tooltip>
            </h1></span
          ><br />
        </v-col>
      </v-row>
      <v-dialog v-model="EditRecordPrompt" persistent max-width="70%">
        <voting-description
          v-if="EditRecordPrompt"
          :recordData="VotingData"
          @hideDialog="hideAddRecordPrompt"
        ></voting-description>
      </v-dialog>
    </v-card-text>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import VotingDescription from "@/view/pages/erp/lom/lom-information/VotingDescription.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    VotingDescription,
  },
  data() {
    return {
      VotingRightsStatus: "",
      DocumentPath: "",
      EditRecordPrompt: false,

      SkeletonLoadingFlag: true,

      EligibleCount: 0,
      selectedData: {},
      VotingData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "voting_rights",
        Action: "list",
      };
      // this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.eligibleMembershipCount();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.EditRecordPrompt = false;
    },
    editData() {
      console.log("editData called");
      var tr = this.VotingData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Votingdescription failed";
        this.toast("error", message);
      }
    },
    editData() {
      console.log("editData called");
      var tr = this.VotingData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Votingdescription failed";
        this.toast("error", message);
      }
    },
    eligibleMembershipCount() {
      console.log("eligibleMembershipCount called");

      this.SkeletonLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-payment/eligible-count";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SkeletonLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          if (flag) {
            var records = response.data.records;
            console.log("records=" + JSON.stringify(records));
            thisIns.VotingData = records;
            console.log("VotingData=" + VotingData);
            // thisIns.toast("success", output);
          } else {
            thisIns.VotingRightsStatus = "Not completed";
            // thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SkeletonLoadingFlag = false;
        });
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.v-dialog {
  display: none;
}
.vertical {
  border-left: 1px solid black;
  height: 140px;
  position: absolute;
  left: 50%;
}
.font {
  font-size: 50px;
  margin-left: 120px;
}
</style>